<template>
  <div class="base-content">
    <query-frame
      :pageVisible="false"
      :searchVisible="false"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import { pointsList,pointsStatus,pointsDelete } from '@/api/points';
import queryMixin from '@/mixin/queryMixin';

export default {
  name: 'PointsList',
  mixins: [queryMixin],
  data() {
    return {
      query: {
        storeName: null,
        name: null,
        status: null,
      },
      // 默认展示表格
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '会员等级',
          dataIndex: 'levelName',
          key: 'levelName',
          ellipsis: true,
        },
        {
          title: '达标金额',
          dataIndex: 'amount',
          key: 'amount',
          ellipsis: true,
        },
        {
          title: '积分(消费1元积分)',
          dataIndex: 'integral',
          key: 'integral',
          ellipsis: true,
        },
        {
          title: '会员折上折',
          dataIndex: 'discount',
          key: 'discount',
          ellipsis: true,

        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          width: 120,
          customRender: (text, row, index) => {
            return (
              <a-switch checked-children="启用" un-checked-children="禁用" checked={row.status === 0} onClick={ () => this.handleStatus(row)}/>
            );
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 220,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return {
              children:
                <section>
                <a-button type="link" size="small" onClick={ () => this.handleEdit(row) }>修改</a-button>
                  <a-divider type="vertical"></a-divider>
                  <a-popconfirm
                    title="确定删除?"
                    v-on:confirm= {() => this.onDelete(row)}
                  >
                    <a href="javascript:;">删除</a>
                  </a-popconfirm>
              </section>,
            }
          }
        },
      ],
      dataList: []
    };
  },
  created() {
    this.handleQuery();
  },
  methods: {
    // 获取列表
    getData() {
      this.dataList = [];
      pointsList().then((data) => {
        console.log(data)
        data.forEach((item) => {
          item.key = item.pointsId
        });
        this.dataList = data;
      });
    },

    // 添加
    handleAdd() {
      this.$router.push({
        name: 'pointsAdd'
      });
    },

    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'pointsEdit',
        params: {
          id: row.pointsId
        }
      });
    },
    // 启用或者禁用会员
    handleStatus(row) {
      const params = {
        pointsId: row.pointsId,
        status: row.status === 0 ? 1 : 0,
        levelName:row.levelName
      };

      pointsStatus(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
    //删除
    onDelete(row){
      // console.log(row.pointsId)
      const params = {
        pointsId: row.pointsId,
        levelName:row.levelName
      };
      pointsDelete(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    }
  }
};
</script>

<style lang="less" scoped>

</style>
