import axios from '../libs/axios';
// 积分规则
const pointsList = (data) => {
  return axios.get('/api/trd/points/list', data);
};

// 新增/修改
const pointsAddOrEdit = (data) => {
  return axios.post('/api/trd/points/save', data);
};

// 信息
const pointsInfo = (pointsId) => {
  return axios.post(`/api/trd/points/info/${pointsId}`);
};

// 启用/者禁
// const pointsStatus = (data) => {
//   return axios.post(`/api/trd/points/status/${data.pointsId}/${data.status}`);
// };
const pointsStatus = (data) => {
  return axios.post(`/api/trd/points/status`,data);
};

// 删除
const pointsDelete = (data) => {
  return axios.post(`/api/trd/points/delete`,data);
};

export {
  pointsList,
  pointsInfo,
  pointsAddOrEdit,
  pointsDelete,
  pointsStatus
};
